import * as React from "react"
import { Link } from "gatsby"
import MenuBar from "../components/MenuBar"


// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>
      <MenuBar />
      <h1 className="title">Page not found</h1>
      <h1 className="title"><Link to="/">Go home</Link></h1>
    </main >
  )
}

export default NotFoundPage
